export const countriesCode = {
	AD: 'Andorre',
	AE: 'Emirats Arabes Unis',
	AF: 'Afghanistan',
	AG: 'Antigua-et-Barbuda',
	AI: 'Anguilla',
	AL: 'Albanie',
	AM: 'Arménie',
	AO: 'Angola',
	AQ: 'Antarctique',
	AR: 'Argentine',
	AS: 'Samoa américaines',
	AT: 'Autriche',
	AU: 'Australie',
	AW: 'Aruba',
	AX: "Îles d'Åland",
	AZ: 'Azerbaïdjan',
	BA: 'Bosnie-Herzégovine',
	BB: 'Barbade',
	BD: 'Bangladesh',
	BE: 'Belgique',
	BF: 'Burkina Faso',
	BG: 'Bulgarie',
	BH: 'Bahreïn',
	BI: 'Burundi',
	BJ: 'Bénin',
	BL: 'St. Barthélemy',
	BM: 'Bermudes',
	BN: 'Brunei',
	BO: 'Bolivie',
	BQ: 'Pays-Bas caribéens',
	BR: 'Brésil',
	BS: 'Les Bahamas',
	BT: 'Bhoutan',
	BV: 'Ile Bouvet',
	BW: 'Bostwana',
	BY: 'Biélorussie',
	BZ: 'Bélize',
	CA: 'Canada',
	CC: 'Îles Cocos (Keeling)',
	CD: 'Congo-Kinshasa',
	CF: 'République centrafricaine',
	CG: 'Congo-Brazzaville',
	CH: 'Suisse',
	CI: "Côte d'Ivoire",
	CK: 'Îles Cook',
	CL: 'Chili',
	CM: 'Cameroun',
	CN: 'Chine',
	CO: 'Colombie',
	CR: 'Costa Rica',
	CU: 'Cuba',
	CV: 'Cap Vert',
	CW: 'Curaçao',
	CX: 'Île Christmas',
	CY: 'Chypre',
	CZ: 'République tchèque',
	DE: 'Allemagne',
	DJ: 'Djibouti',
	DK: 'Danemark',
	DM: 'Dominique',
	DO: 'République dominicaine',
	DZ: 'Algérie',
	EC: 'Équateur',
	EE: 'Estonie',
	EG: 'Egypte',
	EH: 'Sahara occidental',
	ER: 'Érythrée',
	ES: 'Espagne',
	ET: 'Éthiopie',
	FI: 'Finlande',
	FJ: 'Fidji',
	FK: 'Îles Falkland',
	FM: 'Micronésie',
	FO: 'Îles Féroé',
	FR: 'France',
	GA: 'Gabon',
	GB: 'Royaume-Uni',
	GD: 'Grenade',
	GE: 'Géorgie',
	GF: 'Guyane française',
	GG: 'Guernesey',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GL: 'Groenland',
	GM: 'La Gambie',
	GN: 'Guinée',
	GP: 'Guadeloupe',
	GQ: 'Guinée équatoriale',
	GR: 'Grèce',
	GS: 'Géorgie du Sud et îles Sandwich du Sud',
	GT: 'Guatemala',
	GU: 'Guam',
	GW: 'Guinée-Bissau',
	GY: 'Guyane',
	HK: 'Hong Kong RAS Chine',
	HM: 'Îles Heard & McDonald',
	HN: 'Honduras',
	HR: 'Croatie',
	HT: 'Haïti',
	HU: 'Hongrie',
	ID: 'Indonésie',
	IE: 'Irlande',
	IL: 'Israël',
	IM: 'Île de Man',
	IN: 'Inde',
	IO: "Territoire britannique de l'océan Indien",
	IQ: 'Irak',
	IR: 'Iran',
	IS: 'Islande',
	IT: 'Italie',
	JE: 'Jersey',
	JM: 'Jamaïque',
	JO: 'Jordanie',
	JP: 'Japon',
	KE: 'Kenya',
	KG: 'Kirghizistan',
	KH: 'Cambodge',
	KI: 'Kiribati',
	KM: 'Comores',
	KN: 'St. Saint-Kitts-et-Nevis',
	KP: 'Corée du Nord',
	KR: 'Corée du Sud',
	KW: 'Koweït',
	KY: 'Îles Caïmans',
	KZ: 'Kazakhstan',
	LA: 'Laos',
	LB: 'Liban',
	LC: 'St. Lucie',
	LI: 'Liechtenstein',
	LK: 'Sri Lanka',
	LR: 'Libéria',
	LS: 'Lesotho',
	  LT: 'Lituanie',
	LU: 'Luxembourg',
	LV: 'Lettonie',
	LY: 'Libye',
	MA: 'Maroc',
	MC: 'Monaco',
	MD: 'Moldavie',
	ME: 'Monténégro',
	MF: 'St. Martin',
	MG: 'Madagascar',
	MH: 'Îles Marshall',
	MK: 'Macédoine du Nord',
	ML: 'Mali',
	MM: 'Myanmar (Birmanie)',
	MN: 'Mongolie',
	MO: 'Macao RAS Chine',
	MP: 'Îles Mariannes du Nord',
	MQ: 'Martinique',
	MR: 'Mauritanie',
	MS: 'Montserrat',
	MT: 'Malte',
	MU: 'Maurice',
	MV: 'Maldives',
	MW: 'Malawi',
	MX: 'Mexique',
	MY: 'Malaisie',
	MZ: 'Mozambique',
	NA: 'Namibie',
	NC: 'Nouvelle-Calédonie',
	NE: 'Niger',
	NF: 'Île Norfolk',
	NG: 'Nigéria',
	NI: 'Nicaragua',
	NL: 'Pays-Bas',
	NO: 'Norvège',
	NP: 'Népal',
	NR: 'Nauru',
	NU: 'Niué',
	NZ: 'Nouvelle-Zélande',
	OM: 'Oman',
	PA: 'Panama',
	PE: 'Pérou',
	PF: 'Polynésie française',
	PG: 'Papouasie-Nouvelle-Guinée',
	PH: 'Philippines',
	PK: 'Pakistan',
	PL: 'Pologne',
	PM: 'St. Pierre & Miquelon',
	PN: 'Îles Pitcairn',
	PR: 'Porto Rico',
	PS: 'Territoires palestiniens',
	PT: 'Portugal',
	PW: 'Palaos',
	PY: 'Paraguay',
	QA: 'Qatar',
	RE: 'Réunion',
	RO: 'Roumanie',
	RS: 'Serbie',
	RU: 'Russie',
	RW: 'Rwanda',
	SA: 'Arabie Saoudite',
	SB: 'Îles Salomon',
	SC: 'Seychelles',
	SD: 'Soudan',
	SE: 'Suède',
	SG: 'Singapour',
	SH: 'St. Hélène',
	SI: 'Slovénie',
	SJ: 'Svalbard et Jan Mayen',
	SK: 'Slovaquie',
	SL: 'Sierra Leone',
	SM: 'Saint-Marin',
	SN: 'Sénégal',
	SO: 'Somalie',
	SR: 'Surinam',
	SS: 'Soudan du Sud',
	ST: 'Sao Tomé & Principe',
	SV: 'Le Sauveur',
	SX: 'Saint-Martin',
	SY: 'Syrie',
	SZ: 'au Swaziland',
	TC: 'Îles Turques et Caïques',
	TD: 'Tchad',
	TF: 'Terres Australes Françaises',
	TG: 'Togo',
	TH: 'Thaïlande',
	TJ: 'Tadjikistan',
	TK: 'Tokélaou',
	TL: 'Timor-Oriental',
	TM: 'Turkménistan',
	TN: 'Tunisie',
	TO: 'Tonga',
	TR: 'Turquie',
	TT: 'Trinité-et-Tobago',
	TV: 'Tuvalu',
	TW: 'Taïwan',
	TZ: 'Tanzanie',
	UA: 'Ukraine',
	UG: 'Ouganda',
	UM: 'Îles mineures éloignées des États-Unis',
	US: 'États-Unis',
	UY: 'Uruguay',
	UZ: 'Ouzbékistan',
	VA: 'Cité du Vatican',
	VC: 'St. Vincent & les Grenadines',
	VE: 'Venezuela',
	VG: 'Îles Vierges britanniques',
	VI: 'Les iles vierges U.S.',
	VN: 'Vietnam',
	VU: 'Vanuatu',
	WF: 'Wallis & Futuna',
	WS: 'Samoa',
	YE: 'Yémen',
	YT: 'Mayotte',
	ZA: 'Afrique du Sud',
	XK: "Kosovo",
	ZM: 'Zambie',
	ZW: 'Zimbabwe'
  }	 ;


export const europeanCountriesList = {
    'AT': 'Autriche',
    'BE': 'Belgique',
    'BG': 'Bulgarie',
    'HR': 'Croatie',
    'CY': 'Chypre',
    'CZ': 'République Tchèque',
    'DK': 'Danemark',
    'EE': 'Estonie',
    'FI': 'Finlande',
    'FR': 'France',
    'DE': 'Allemagne',
    'GR': 'Grèce',
    'HU': 'Hongrie',
    'IE': 'Irlande',
    'IT': 'Italie',
    'LV': 'Lettonie',
    'LT': 'Lituanie',
    'LU': 'Luxembourg',
    'MT': 'Malte',
    'NL': 'Pays-Bas',
    'PL': 'Pologne',
    'PT': 'Portugal',
    'RO': 'Roumanie',
    'SK': 'Slovaquie',
    'SI': 'Slovénie',
    'ES': 'Espagne',
    'SE': 'Suède',
    'GB': 'Royaume-Uni',
  };



export const currenciesList = {
	EUR: "€",
	USD: "$",
	GBP: "£"
}