export const sessions = {
    flash_register_success: "Votre inscription au plan Basic est effective.",
    my_account_link: "/mon-compte",
    payment_link: "/paiement",
    prices_link: "/tarifs",
}

export const useInitUser = {
    errorMessage: {
        userToken: "Désolé, nous ne pouvons pas récupérer vos informations. Notre équipe technique a été informée. Veuillez actualiser la page ou réessayer plus tard.",
        apiKey: "Nous ne sommes pas en mesure de récupérer votre clé API. Veuillez valider le captcha sur la page Mon compte pour activer votre clé API.",
        billingInfo: "Désolé, nous ne pouvons pas récupérer vos informations de facturation. Notre équipe technique a été informée. Veuillez actualiser la page ou réessayer plus tard.",
        billingHistory: "Désolé, nous ne pouvons pas récupérer votre historique de facturation. Notre équipe technique a été informée. Veuillez actualiser la page ou réessayer plus tard.",
        userInfo: "Désolé, nous ne pouvons pas récupérer vos informations d'utilisateur. Notre équipe technique a été informée. Veuillez actualiser la page ou réessayer plus tard.",
        paymentInfo: "Désolé, nous ne pouvons pas récupérer vos informations de paiement. Notre équipe technique a été informée. Veuillez actualiser la page ou réessayer plus tard.",
        apiUsage: "Désolé, nous ne pouvons pas récupérer vos informations API. Notre équipe technique a été informée. Veuillez actualiser la page ou réessayer plus tard.",
        apiUsageHistory: "Désolé, nous ne pouvons pas récupérer l'historique de votre API. Notre équipe technique a été informée. Veuillez actualiser la page ou réessayer plus tard.",
        learnable: "Nous n'avons pas pu définir votre clé API. Veuillez réessayer plus tard.",
        anonymize: "Nous n'avons pas pu définir votre clé API. Veuillez réessayer plus tard.",
        removeUserAccount: "Nous n'avons pas pu supprimer votre compte. Veuillez réessayer plus tard.",
        availablePlans: "Nous n'avons pu obtenir aucun plan disponible. Veuillez actualiser la page si vous rencontrez un problème."
    },
    updatedInformation: {
        updateBillingInfo: "informations de facturation",
    },
    return_flash_updated_success: updatedInformation => `Vos ${updatedInformation} ont été mise à jour avec succès.`,
    return_flash_updated_error: updatedInformation => `Vos ${updatedInformation} n'ont pas pu être mise à jour. Veuillez réessayer ultérieurement.`,
}


export const useUpdateUserData = {
    flash_update_empty: "Il n'y avait aucune information à mettre à jour.",
    flash_update_name_success: "Votre nom a été mis à jour avec succès.",
    flash_update_name_error: "Votre nom n'a pas pu être mis à jour. Veuillez réessayer ultérieurement.",
    flash_connexion_old: "Votre connexion est trop ancienne pour modifier votre adresse e-mail. Veuillez vous déconnecter et vous reconnecter pour modifier votre adresse e-mail.",
    flash_update_email_success: "Votre email a été mis à jour avec succès.",
    flash_update_email_error: "Votre email n'a pas pu être mis à jour. Veuillez réessayer ultérieurement.",
    flash_vat_unvalid: "Votre numéro de TVA n'est pas valide.",
    flash_limit_payment: "Vous ne pouvez pas modifier ces limites sans cartes de paiement enregistrées.",
    flash_no_value_limit: "Il n'y a pas de nouvelles valeurs à mettre à jour la limite stricte ou la limite flexible.",
    return_flash_soft_limit: quota => `La valeur minimale acceptée pour la limite flexible est ${quota}.`,
    return_flash_hard_limit: quota => `La valeur minimale acceptée pour la limite stricte est ${quota}.`,
    flash_higher_limit: "Votre limite flexible ne peut pas être supérieure à votre limite stricte.",
    flash_update_soft_limit_error: "Nous n'avons pas pu mettre à jour votre limite flexible. Veuillez réessayer plus tard.",
    flash_update_hard_limit_error: "Nous n'avons pas pu mettre à jour votre limite stricte. Veuillez réessayer plus tard.",
    flash_update_soft_limit_success: "Votre limite flexible a été mise à jour avec succès.",
    flash_update_hard_limit_success: "Votre limite stricte a été mise à jour avec succès.",
    flash_update_default_card_error: "Nous n'avons pas pu mettre à jour votre carte par défaut. Veuillez réessayer plus tard.",
    flash_update_default_card_success: "Votre carte par défaut a été mise à jour avec succès.",
    flash_delete_account: "Un email a été envoyé à votre adresse. Cliquez sur le lien pour finaliser la suppression de votre compte.",
    return_flash_delete_card_subscription_error: plan => `Vous ne pouvez pas supprimer toutes vos cartes car vous êtes abonné à un forfait ${plan}. Veuillez ajouter une autre carte avant de supprimer celle-ci ou souscrivez d'abord à un plan BASIC (gratuit).`,
    return_flash_delete_card_error: last4 => `Nous n'avons pas pu supprimer votre carte n° **** **** **** ${last4}. Veuillez réessayer plus tard`,
    return_flash_delete_card_success: last4 => `Votre carte n° **** **** **** ${last4} a été supprimée avec succès.`,
    return_flash_register_plan_success: plan => `Vous avez souscrit avec succès au plan ${plan}.`,
    return_flash_register_plan_error: plan => `Nous n'avons pas pu vous inscrire au plan ${plan}. Veuillez réessayer plus tard.`,
    my_account_link: "/mon-compte",
}
