exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-de-namsor-js": () => import("./../../../src/pages/a-propos-de-namsor.js" /* webpackChunkName: "component---src-pages-a-propos-de-namsor-js" */),
  "component---src-pages-connexion-js": () => import("./../../../src/pages/connexion.js" /* webpackChunkName: "component---src-pages-connexion-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-csv-excel-js": () => import("./../../../src/pages/csv-excel.js" /* webpackChunkName: "component---src-pages-csv-excel-js" */),
  "component---src-pages-documentation-api-js": () => import("./../../../src/pages/documentation-api.js" /* webpackChunkName: "component---src-pages-documentation-api-js" */),
  "component---src-pages-enumerateurs-api-js": () => import("./../../../src/pages/enumerateurs-api.js" /* webpackChunkName: "component---src-pages-enumerateurs-api-js" */),
  "component---src-pages-fonctionnalites-features-json-page-js": () => import("./../../../src/pages/fonctionnalites/{featuresJson.page}.js" /* webpackChunkName: "component---src-pages-fonctionnalites-features-json-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscription-js": () => import("./../../../src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-mon-compte-js": () => import("./../../../src/pages/mon-compte.js" /* webpackChunkName: "component---src-pages-mon-compte-js" */),
  "component---src-pages-mot-de-passe-oublie-js": () => import("./../../../src/pages/mot-de-passe-oublie.js" /* webpackChunkName: "component---src-pages-mot-de-passe-oublie-js" */),
  "component---src-pages-outils-de-developpeur-js": () => import("./../../../src/pages/outils-de-developpeur.js" /* webpackChunkName: "component---src-pages-outils-de-developpeur-js" */),
  "component---src-pages-paiement-js": () => import("./../../../src/pages/paiement.js" /* webpackChunkName: "component---src-pages-paiement-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("./../../../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-tarifs-js": () => import("./../../../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */),
  "component---src-pages-termes-et-conditions-js": () => import("./../../../src/pages/termes-et-conditions.js" /* webpackChunkName: "component---src-pages-termes-et-conditions-js" */)
}

